// Chakra imports
import { Box, Grid } from "@chakra-ui/react";

// Custom components
import InstructorRetoolEmbed from "views/admin/default/components/InstructorRetoolEmbed";

// Contexts
import { AuthContext } from 'contexts/AuthContext';

// Assets
import React, { useContext } from "react";

export default function Overview() {
  const { user } = useContext(AuthContext);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <InstructorRetoolEmbed
        user = {user}
        currentRoute = {'opportunities'}
      />
    </Box>
  );
}
