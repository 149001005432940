import React, { useState, useEffect, useContext } from 'react';
import Card from "components/card/Card.js";
import Retool from "react-retool";
import { functions } from "lib/firebase";
import { httpsCallable } from "firebase/functions";
import { AuthContext } from 'contexts/AuthContext';
import { useRefresh } from 'contexts/RefreshContext';
import { useColorMode } from "@chakra-ui/react";

const RetoolWrapper = ({ user, currentRoute }) => {
  const [retoolEmbedUrl, setRetoolEmbedUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const { refresh } = useRefresh(); // Access refresh function from context
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (!user || !user.email || !currentRoute) {
      // Wait until the necessary data is available
      return;
    }

    const email = user.email;
    // const email = 'xanag@yahoo.com'; // Hardcoded email for testing

    const fetchEmbedUrl = async () => {
      setLoading(true);
      const getRetoolEmbedURL = httpsCallable(functions, 'getRetoolEmbedURL');
      try {
        const response = await getRetoolEmbedURL({ email, colorMode, currentRoute });
        if (response && response.data) {
          setRetoolEmbedUrl(response.data.embedUrl);
        } else {
          console.error("No embed URL returned");
        }
      } catch (error) {
        console.error("Error fetching embed URL:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmbedUrl();
  }, [user, refresh, currentRoute, colorMode]);

  if (loading || !user || !user.email) {
    return <div>Loading...</div>; // Display a loading message while waiting for the necessary data
  }

  return (
    retoolEmbedUrl && (
      <Card mb='20px' w='100%' p='20px' h='975px'>
        <Retool url={retoolEmbedUrl} />
      </Card>
    )
  );
};

const InstructorRetoolEmbed = ({ user, currentRoute }) => {
  return <RetoolWrapper user={user} currentRoute={currentRoute} />;
};

export default InstructorRetoolEmbed;